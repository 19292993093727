import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="main-heading text-center">Frequently Asked Questions - IEO Development Company</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is IEO Service?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">An IEO or Initial Exchange Offering service, is a fundraising method within the cryptocurrency industry. It involves selling tokens through well-known cryptocurrency exchange platforms. The exchange platforms facilitate the sale of the token to raise funds via fundraising events for the startups.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                What is the difference between ICO and IEO?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The difference between ICO and IEO lies in their launch platforms. IEO services are launched on popular exchange platforms, while ICOs typically occur on newly created websites. This means IEOs benefit from the robust security protocols provided by established exchanges, reducing the risk of fraud compared to ICOs. To enhance security measures, IEOs were introduced. Crypto exchange websites like Binance, OK Jumpstart, Bybit Launchpad, BitMEX, KuCoin Spotlight, etc.. have IEO on their websites for the investors to participate.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                Is it difficult to develop an IEO Platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Yes, It is slightly difficult to develop an IEO platform. While it is complex, with the right team and resources, it is possible to develop an IEO platform successfully. If you’re already equipped with an exchange, then integrating the IEO is a seamless process.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How to find the right IEO development company?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Finding the right IEO development company requires a thorough analysis, where you can define your requirements, research, and shortlist the companies. Also, check the portfolio and experience, assess technology expertise, evaluate regulatory compliance, check for client reviews and testimonials, discuss the terms and contracts, and then finally request proposals.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection