import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>IEO</span> Development Company</h1>
                <p className='pharagraph'>For all your IEO development services and crafting cutting-edge solutions according to your IEO needs. Our visionary developers infuse innovation into every aspect of your Initial Exchange Offering. We ensure that your IEO project stands out in this competitive crypto market. With a deep understanding of all the crypto industry trends, we deliver results that exceed your expectations. </p>
                <p className='pharagraph mb-1'>Trust coinsclone - Your IEO partner from Concept to Completion.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Ready to launch your IEO project?</a>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ieo/ieo-development-company.webp"
                alt="ieo development company"
                className='d-none d-md-block float-end'
                width={454}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection